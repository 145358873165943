@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Define your color variables */
  --custom-blue-main-background: #f4fbff;
  --custom-blue-details-background: #eff9ff;
  --custom-blue-button: #e0e7ff;
  --custom-blue-hover: #4ea0db;
  --custom-blue-border: #b0e0ff;
  --custom-blue-tagbg: #b0e0ff;
  --custom-blue-text: #b0e0ff;
  --custom-blue-call-text: #01c6f7;
  --custom-blue-activeBorder: #0090ee;
  --custom-blue-lightBorder: #ddf1fe;
  --active-links: #4472c4;
  --list-chevron: #0083fc;
  --custom-orange-text: #ffae2c;

  --wild-watermelon-button: #ff5976;
  --gray-text-100: #8e98a8;
  --gray-text-200: #3b395f;
  --gray-text-500: #182026;
  --gray-text-600: #7f7f7f;
  --gray-text-800: #181818;
  --gray-text-900: #000000;
  --theme-green: #00a638;
  --theme-danger: #ff6d63;
  --theme-yellow: #f09d18;
  --theme-royal-blue: #334a58;
  --theme-grey: #989898;
  --shadow: #dde4e8;
  --border-light: #d0d0d0;
  --divider: #b0b0b0;
  --grey-400: #666666;
  --table-data-400: #464e5f;
  --hot-pink: #f4326c;
  --active-link: #4472c4;
}

body {
  @apply font-raleway;
  /* This applies Raleway font to the entire body */
  font-variant-numeric: lining-nums;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #dadada;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
input {
  cursor: pointer;
}

input:focus {
  outline: none;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.scrollbar-hide {
  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.checkbox input:checked {
  border-color: red;
  background-color: red;
}

.title {
  letter-spacing: 0.1px;
}

.search-bar {
  box-shadow: 0 6px 15px rgba(205, 205, 205, 0.1);
}

.line-h-0 {
  line-height: 0.4px;
  margin-bottom: 2px;
}

.right-form {
  width: calc(100% - 375px);
}


/* Overwriting React multiselect dropdown */
.optionContainer .option:hover,
.optionContainer .highlightOption.highlight:hover {
  background-color: #b0e0ff !important;
  color: white !important;
}

.optionContainer .highlightOption.highlight {
  background-color: white !important;
  color: black !important;
}

/* Table related CSS */
.rdt_TableBody div {
  /* color: var(--table-data-400); */
  font-weight: 500;
  font-size: 14px;
}
.rdt_Table {
  z-index: 0;
}
.rdt_TableHead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.rdt_TableHeadRow {
  min-height: 48px !important;
}
/* .rdt_TableBody{
  max-height: calc(100vh - 400px);
} */
.theme-table .rdt_Table {
  /* max-height: calc(100vh - 355px) !important; */
  max-height: calc(100vh - 200px) !important;
  overflow: auto;
  background-color: transparent !important;
}
.theme-table.table-dash .rdt_Table {
  /* height: calc(100vh - 462px) !important; */
  /* height: calc(100vh - 315px) !important; */
  max-height: calc(100vh - 280px) !important;
}
.theme-table.table-dash.activeCard .rdt_Table {
  /* height: calc(100vh - 462px) !important; */
  max-height: calc(100vh - 415px) !important;
}

.sort-icon {
  color: #abb0b3;
}

.sort-icon:hover {
  color: black;
}
div[role="columnheader"]:hover {
  opacity: 1;
}

.rdt_TableRow {
  margin: 4px 0;
  padding: 4px 0;
  border-bottom: none !important;
}

.rdt_TableRow:nth-child(even) {
  background-color: #f4f8fa;
}

.rdt_TableRow:hover {
  border-bottom: 1px solid #0000001a;
  transition: 0.5s;
  background-color: #eff9ff;
}
.CircularProgressbar-text {
  font-weight: 800;
}
.rdt_TableBody div.view-block {
  color: #272830;
  font-weight: 600;
}
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.side-slider {
  position: fixed;
  right: 0;
  top: 25vh;
  cursor: pointer;
  transition: 0.5s;
}
.side-slider:hover {
  transform: scale(1.05);
  transform-origin: right;
}

/* multiplae tags input */
.rti--container {
  border: 2px solid #b0e0ff !important;
  border-radius: 12px !important;
  /* padding: 0 10px !important; */
  min-height: 44px !important;
}

.rti--input {
  background-color: white !important;
  font-size: 14px !important;
  width: 100% !important;
}

.rti--tag {
  background-color: var(--custom-blue-tagbg) !important;
  border-radius: 8px !important;
  padding: 2px 5px !important;
}

/* Date Picker */
.react-datepicker-wrapper {
  display: inline !important;
}

.audio-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audio-player button {
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.eLRNkY {
  min-width: 160px !important;
}
.custom-data-table-wrapper {
  overflow: auto !important;
  max-height: calc(100vh - 240px);
}
.rdt_Pagination {
  justify-content: start !important;
}
.gALmNI {
  margin-left: auto;
}
.css-levciy-MuiTablePagination-displayedRows {
  @apply font-raleway  !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-1xc3v61-indicatorContainer {
  color: #000000 !important;
}
.react-datepicker__input-container input {
  background-image: url(../../assets/images/calendar-day.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98% 52%;
}

.rti--input {
  width: 120px !important;
}

/* Styles for individual tags */
.ReactTags__tag {
  background: #b0e0ff;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 5px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap; /* Prevent text from wrapping */
}

.ReactTags__remove {
  cursor: pointer;
  margin-left: 8px;
  color: white;
}

.ReactTags__tagInputField {
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  outline: none;
  padding: 5px 10px;
  font-size: 14px;
}

/* Assuming editor-container wraps the entire editor */
.editor-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Align the toolbar to the right and below the editor */
.ql-toolbar {
  position: absolute;
  bottom: 0px; /* Adjust based on the toolbar height */
  right: 0;
  z-index: 10; /* Ensure toolbar stays above content */
  border: 0px !important;
}

.quill {
  height: inherit !important;
}

/* Ensure that the editor height does not overlap the toolbar */
.ql-container {
  border: 2px solid var(--custom-blue-border) !important;
  border-radius: 12px !important;
  min-height: 100% !important;
  height: 100% !important;
}
.min-width {
  width: min-content;
}

/* sidebar */
.ps-submenu-expand-icon {
  display: none;
}
.ps-menu-button {
  padding-right: 4px !important;
  padding-left: 0px !important;
}

@media screen and (max-width: 1320px) {

  .theme-table.table-dash.activeCard .rdt_Table{
    max-height: calc(100vh - 365px) !important;
  }
  .theme-table.table-dash .rdt_Table{
    max-height: calc(100vh - 240px) !important;
  }
}

@media screen and (max-width: 1200px) {
  .otp-input input {
    font-size: 1.7vw;
  }
 
}

@media screen and (max-width: 992px) {
  .otp-input input {
    font-size: 16px !important;
    width: 100% !important;
    max-width: 78px;
  }

  .otp-input div {
    gap: 30px !important;
  }

  .right-form {
    width: 100%;
  }

  .abs-img {
    height: -webkit-fill-available;
  }
}

@media screen and (max-width: 662px) {
  .otp-input input {
    font-size: 16px !important;
    width: 100% !important;
    max-width: 100%;
  }

  .otp-input div {
    gap: 10px !important;
  }

  .right-form {
    width: 100%;
  }

  .abs-img {
    height: -webkit-fill-available;
  }
}
.react-datepicker__close-icon{
  right: 28px !important;
}
.css-1xc3v61-indicatorContainer{
  padding: 4px 4px 4px 0  !important;
}